import React from 'react';
import { makeStyles } from '@material-ui/core';
import { longDark, longLight } from './logos';
import { PreferredColorScheme, usePreferredColorScheme } from './usePreferredColorScheme';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 78,
    marginLeft: '-24px',
  },
});

export default () => {
  const classes = useStyles();
  const colorScheme = usePreferredColorScheme();
  const logo = colorScheme === PreferredColorScheme.Dark ? longLight : longDark;
  return <img src={logo} alt="babel-logo" className={classes.svg} />;
};
