import React from 'react';
import { makeStyles } from '@material-ui/core';
import { PreferredColorScheme, usePreferredColorScheme } from './usePreferredColorScheme';
import { squareDark, squareLight } from './logos';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
    marginLeft: '-20px',
    marginTop: '2px',
  },
});

export default () => {
  const classes = useStyles();
  const colorScheme = usePreferredColorScheme();
  const logo = colorScheme === PreferredColorScheme.Dark ? squareLight : squareDark;
  return <img src={logo} alt="babel-logo" className={classes.svg} />;
};

