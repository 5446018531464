import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';
import { createUnifiedTheme, palettes, UnifiedThemeProvider } from '@backstage/theme';

const notificationColors = {
  error: {
    main: '#D1334A',
  },
  warning: {
    main: '#F7B124',
  },
  info: {
    main: '#009EE8',
  },
  success: {
    main: '#5DA335',
  },
};

const fonts = '"EF Circular", "Helvetica", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif';
const defaultHomePage = 'home';

const customPaletteDark = {
  ...palettes.dark,
  ...notificationColors,
  background: {
    default: '#2F2F2F',
  },
  navigation: {
    background: '#191919',
    indicator: '#8f5e15',
    color: '#fff',
    selectedColor: '#F7F7F7',
  },
};

const customPaletteLight = {
  ...palettes.light,
  ...notificationColors,
  background: {
    default: '#F7F7F7',
  },
  navigation: {
    background: '#EFEFEF',
    indicator: '#8f5e15',
    color: '#191919',
    selectedColor: '#000',
  },
};

const defaultDark = createUnifiedTheme({
  palette: customPaletteDark,
  fontFamily: fonts,
  defaultPageTheme: defaultHomePage,
});

const defaultLight = createUnifiedTheme({
  palette: customPaletteLight,
  fontFamily: fonts,
  defaultPageTheme: defaultHomePage,
});


export const themes: (Partial<AppTheme> & Omit<AppTheme, 'theme'>)[] = [
  {
    id: 'default-dark',
    title: 'Default Dark',
    variant: 'dark',
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={defaultDark} children={children} />
    ),
  },
  {
    id: 'default-light',
    title: 'Default Light',
    variant: 'light',
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={defaultLight} children={children} />
    ),
  },
];
