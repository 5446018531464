enum GreetingVariant {
  hello = 'hello',
  morning = 'good morning',
  afternoon = 'good afternoon',
  evening = 'good evening',
}

enum Language {
  english = 'English',
  german = 'German',
  swissGerman = 'Swiss German',
  japanese = 'Japanese',
  mandarin = 'Mandarin',
  korean = 'Korean',
  spanish = 'Spanish',
  french = 'French',
  italian = 'Italian',
  swedish = 'Swedish',
  dutch = 'Dutch',
  portuguese = 'Portuguese',
  polish = 'Polish',
  romanian = 'Romanian',
  hungarian = 'Hungarian',
  lithuanian = 'Lithuanian',
  russian = 'Russian',
}

interface Variant {
  text: string;
  romanized?: string;
}

interface Greeting {
  language: Language;
  hello: Variant;
  morning: Variant;
  afternoon: Variant;
  evening: Variant;
}

interface CurrentGreeting {
  language: Language,
  text: string;
  romanized?: string;
  variant: GreetingVariant;
}

const greetings: Greeting[] = [
  {
    language: Language.english,
    hello: { text: 'Hello' },
    morning: { text: 'Good morning' },
    afternoon: { text: 'Good Afternoon' },
    evening: { text: 'Good evening' },
  },
  {
    language: Language.german,
    hello: { text: 'Hallo' },
    morning: { text: 'Guten morgen' },
    afternoon: { text: 'Guten Tag' },
    evening: { text: 'Guten abend' },
  },
  {
    language: Language.swissGerman,
    hello: { text: 'Grüezi' },
    morning: { text: 'Guete morge' },
    afternoon: { text: 'Grüezi' },
    evening: { text: 'Guete Abig' },
  },
  {
    language: Language.dutch,
    hello: { text: 'Hallo' },
    morning: { text: 'Goedemorgen' },
    afternoon: { text: 'Goedemiddag' },
    evening: { text: 'Goedenavond' },
  },
  {
    language: Language.japanese,
    hello: { text: 'こんにちは', romanized: 'konnichiwa' },
    morning: { text: 'おはよう', romanized: 'Ohayō' },
    afternoon: { text: 'こんにちは', romanized: 'Konnichiwa' },
    evening: { text: 'こんばんは', romanized: 'Konbanwa' },
  },
  {
    language: Language.mandarin,
    hello: { text: '你好', romanized: 'Nǐ hǎo' },
    morning: { text: '早安', romanized: 'Zǎo ān' },
    afternoon: { text: '午安', romanized: 'Wǔ ān' },
    evening: { text: '晚安', romanized: 'Wǎn\'ān' },
  },
  {
    language: Language.korean,
    hello: { text: '안녕하세요', romanized: 'annyeonghaseyo' },
    morning: { text: '안녕하십니까', romanized: 'annyeonghasibnikka' },
    afternoon: { text: '안녕하십니까', romanized: 'annyeonghasibnikka' },
    evening: { text: '안녕하십니까', romanized: 'annyeonghasibnikka' },
  },
  {
    language: Language.spanish,
    hello: { text: 'Hola' },
    morning: { text: 'Buenos días' },
    afternoon: { text: 'Buenas tardes' },
    evening: { text: 'Buenas noches' },
  },
  {
    language: Language.french,
    hello: { text: 'Salut' },
    morning: { text: 'Bonjour' },
    afternoon: { text: 'Bonjour' },
    evening: { text: 'Bonjour' },
  },
  {
    language: Language.italian,
    hello: { text: 'Ciao' },
    morning: { text: 'Buongiorno' },
    afternoon: { text: 'Buon pomeriggio' },
    evening: { text: 'Buonasera' },
  },
  {
    language: Language.swedish,
    hello: { text: 'Hej' },
    morning: { text: 'God morgon' },
    afternoon: { text: 'God eftermiddag' },
    evening: { text: 'God kväll' },
  },
  {
    language: Language.portuguese,
    hello: { text: 'Olá' },
    morning: { text: 'Bom dia' },
    afternoon: { text: 'Boa tarde' },
    evening: { text: 'Boa noite' },
  },
  {
    language: Language.polish,
    hello: { text: 'Cześć' },
    morning: { text: 'Dzień dobry' },
    afternoon: { text: 'Dzień dobry' },
    evening: { text: 'Dobry wieczór' },
  },
  {
    language: Language.romanian,
    hello: { text: 'Salut' },
    morning: { text: 'Bună dimineața' },
    afternoon: { text: 'Bună ziua' },
    evening: { text: 'Bună seara' },
  },
  {
    language: Language.hungarian,
    hello: { text: 'Szia' },
    morning: { text: 'Jó reggelt' },
    afternoon: { text: 'Jó napot' },
    evening: { text: 'Jó estét' },
  },
  {
    language: Language.lithuanian,
    hello: { text: 'Sveiki' },
    morning: { text: 'Labas rytas' },
    afternoon: { text: 'Laba diena' },
    evening: { text: 'Labas vakaras' },
  },
  {
    language: Language.russian,
    hello: { text: 'Привет', romanized: 'Privet' },
    morning: { text: 'Доброе утро', romanized: 'Dobroe utro' },
    afternoon: { text: 'Добрый день', romanized: 'Dobryy den' },
    evening: { text: 'Добрый вечер', romanized: 'Dobryy vecher' },
  },
];

export const getGreeting = (): CurrentGreeting => {
  let greeting: CurrentGreeting;
  const random = greetings[Math.floor(Math.random() * greetings.length)];

  const currentHour = new Date().getHours();
  if (currentHour > 5 && currentHour < 12) {
    greeting = {
      language: random.language,
      variant: GreetingVariant.morning,
      text: random.morning.text,
      romanized: random.morning.romanized,
    };
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = {
      language: random.language,
      variant: GreetingVariant.afternoon,
      text: random.afternoon.text,
      romanized: random.afternoon.romanized,
    };
  } else if (currentHour >= 18 && currentHour < 22) {
    greeting = {
      language: random.language,
      variant: GreetingVariant.evening,
      text: random.evening.text,
      romanized: random.evening.romanized,
    };
  } else {
    greeting = {
      language: random.language,
      variant: GreetingVariant.hello,
      text: random.hello.text,
      romanized: random.hello.romanized,
    };
  }

  return greeting;
};
