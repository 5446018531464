import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {
  Annotation,
  getWellKnownAnnotationsFromEntity,
} from './wellKnownAnnotations';


export const WellKnownAnnotationsCard = () => {
  const { entity } = useEntity();
  const wkAnnotations: Annotation[] = getWellKnownAnnotationsFromEntity({ entity });
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><Typography variant='h6'>Well-Known Annotations</Typography></TableCell>
            <TableCell><Typography variant='h6'></Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {wkAnnotations.map((row: Annotation) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                <Tooltip title={row.description}>
                  <Typography variant="body1">{row.name}</Typography>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">{row.value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
