import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { InfoCard } from '@backstage/core-components';
import { AboutField } from '@backstage/plugin-catalog';
import { Divider, Grid } from '@material-ui/core';

interface Sla {
  tier?: string;
  response?: string[]
  description?: string;
}

interface SlaPanelRowProps {
  resource: string;
  tier?: string;
  responseTimes?: string[];
  description?: string;
}

const knownTiers = [
  'tin',
  'bronze',
  'silver',
  'gold',
  'platinum',
  'diamond',
];

const SlaPanelRow = ({ resource, tier, responseTimes, description }: SlaPanelRowProps) => {
  const isWellKnownTier = knownTiers.includes(tier ?? '');

  let tierValue = tier;
  if (!tier) {
    tierValue = '(No tier specified)';
  } else if (!isWellKnownTier) {
    tierValue = `Unknown tier: ${tier}`;
  }

  const responseTimeLabel = 'Response times';
  let responseTimeElement;
  if (!responseTimes) {
    responseTimeElement = <AboutField label={responseTimeLabel} value="(No response times defined)"/>;
  } else {
    responseTimeElement = responseTimes.length === 1
      ? <AboutField label={responseTimeLabel} value={responseTimes[0]} gridSizes={{ xs: 12, sm: 6 }} />
      : (
        <AboutField label={responseTimeLabel} gridSizes={{ xs: 12, sm: 6 }}>
          <ul>
            {responseTimes?.map((item) => <li key={item}>{item}</li>)}
          </ul>
        </AboutField>
      );
  }

  return (
    <div>
      <h4>{resource}</h4>
      <div>
        {
          description
            ? <AboutField label="Description" value={description} />
            : null
        }
        <Grid container>
          <AboutField label="Tier" value={tierValue} gridSizes={{ xs: 12, sm: 6 }} />
          {responseTimeElement}
        </Grid>
      </div>
    </div>
  );
};

export const SlaPanel = () => {
  const { entity } = useEntity();

  const sla = entity?.spec?.sla as null | Record<string, Sla>;
  if (!sla) {
    return (
      <InfoCard title="SLA" variant="gridItem">
        <p>No SLAs have been defined for this {entity.kind}</p>
      </InfoCard>
    );
  }

  const defaultSla = sla?.default;
  const endpointSpecificSlas = Object.entries(sla).filter(([key]) => key !== 'default');

  return (
    <InfoCard title="SLA" variant="gridItem">
      <div>
        {
          defaultSla
            ? <SlaPanelRow resource={`${entity.kind} Default`} tier={defaultSla.tier} responseTimes={defaultSla.response} description={defaultSla.description}/>
            : <p>No default tier has been defined for this {entity.kind}</p>
        }
        {
          endpointSpecificSlas.map(([resource, data]) => (
            <div key={resource}>
              <Divider />
              <SlaPanelRow resource={resource} tier={data.tier} responseTimes={data.response} description={data.description} />
            </div>
          ))
        }
      </div>
    </InfoCard>
  );
};
