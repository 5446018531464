import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';
import { useObservable } from 'react-use';

export enum PreferredColorScheme {
  Light,
  Dark,
}

export const usePreferredColorScheme = (): PreferredColorScheme => {
  const appThemeApi = useApi(appThemeApiRef);
  appThemeApi.getActiveThemeId();
  const themeId = useObservable(
    appThemeApi.activeThemeId$(),
    appThemeApi.getActiveThemeId(),
  );

  if (!themeId) {
    const prefersColorSchemeIsDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return prefersColorSchemeIsDark ? PreferredColorScheme.Dark : PreferredColorScheme.Light;
  }

  return themeId.includes('dark') ? PreferredColorScheme.Dark : PreferredColorScheme.Light;
};
