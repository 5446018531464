import { Entity } from '@backstage/catalog-model';

export enum AnnotationCategories {
  SRE,
  Security,
  DBA,
  Integrations,
}

export type Annotation = {
  name: string,
  category: AnnotationCategories,
  description: string,
  value?: string
};

export const wellKnownAnnotations: Annotation[] = [
  {
    name: 'eflangtech.com/dotnet-version',
    category: AnnotationCategories.SRE,
    description: 'Dotnet version',
  },
  {
    name: 'sre.eflangtech.com/cloudwatch-log-group',
    category: AnnotationCategories.SRE,
    description: 'ARN of the cloudwatch logging group',
  },
  {
    name: 'sre.eflangtech.com/alerts-teams-channel',
    category: AnnotationCategories.SRE,
    description: 'MS Teams channel to which the production alerts are sent',
  },
  {
    name: 'sre.eflangtech.com/alerts-mailing-group',
    category: AnnotationCategories.SRE,
    description: 'Mailing group to which the production alerts are sent',
  },

  {
    name: 'detectify.com/project-id',
    category: AnnotationCategories.Security,
    description: "Find this in your projects' reports page URL: https://detectify.com/dashboard/***",
  },
  {
    name: 'snyk.io/org-name',
    category: AnnotationCategories.Security,
    description: 'The slug of the Snyk org name. Along with the "snyk.io/project-ids" enables the Snyk integration',
  },
  {
    name: 'snyk.io/project-ids',
    category: AnnotationCategories.Security,
    description: 'A comma separated list of Snyk project IDs. Along with the "snyk.io/org-name" enables the Snyk integration',
  },

  {
    name: 'github.com/project-slug',
    category: AnnotationCategories.Integrations,
    description: 'The slug of the github repo, e.g. "ILC-Technology/babel"',
  },
  {
    name: 'backstage.io/techdocs-ref',
    category: AnnotationCategories.Integrations,
    description: 'Enabled the techdocs feature in babel. Must point to a folder with an mkdocs.yaml file.',
  },

  {
    name: 'dba.eflangtech.com/ag-listener',
    category: AnnotationCategories.DBA,
    description: 'the AG listener',
  },
  {
    name: 'dba.eflangtech.com/ag-name',
    category: AnnotationCategories.DBA,
    description: 'the AG name',
  },
  {
    name: 'dba.eflangtech.com/dns-name',
    category: AnnotationCategories.DBA,
    description: 'DNS name of the cluster',
  },
  {
    name: 'dba.eflangtech.com/domain',
    category: AnnotationCategories.DBA,
    description: 'Clusters windows domain name',
  },
  {
    name: 'dba.eflangtech.com/endpoint',
    category: AnnotationCategories.DBA,
    description: 'The RDS endpoint of the cluster (which may not expose raw IP addresses)',
  },
  {
    name: 'dba.eflangtech.com/nlb-endpoint',
    category: AnnotationCategories.DBA,
    description: 'The endpoint of the Network Load Balancer that points to the cluster',
  },
  {
    name: 'dba.eflangtech.com/mssql-version',
    category: AnnotationCategories.DBA,
    description: 'version of MSSQL being used',
  },
  {
    name: 'dba.eflangtech.com/windows-version',
    category: AnnotationCategories.DBA,
    description: 'version of Windows ran by the host machine',
  },
  {
    name: 'dba.eflangtech.com/ip-addresses',
    category: AnnotationCategories.DBA,
    description: 'comma-separated list of IP addresses that point to the cluster. First one is master',
  },
  {
    name: 'dba.eflangtech.com/secondary-ip-addresses',
    category: AnnotationCategories.DBA,
    description: 'comma-separated list of secondary IP addresses that point to the cluster. First one is master',
  },
  {
    name: 'dba.eflangtech.com/ports',
    category: AnnotationCategories.DBA,
    description: 'the port exposed by the primary cluster',
  },
  {
    name: 'dba.eflangtech.com/secondary-ports',
    category: AnnotationCategories.DBA,
    description: 'the port exposed by the secondary cluster',
  },
];


export function getWellKnownAnnotationsFromEntity({ entity }: { entity: Entity }): Annotation[] {
  const annotations = entity?.metadata.annotations;
  if (!annotations) {
    return [];
  }
  const annotationsNames = Object.keys(annotations);

  const filtered = wellKnownAnnotations.
    reduce((acc, a) => {
      if (annotationsNames.includes(a.name)) {
        a.value = annotations[a.name];
        acc.push(a);
      } 
      return acc;
    }, [] as Annotation[]);

  return filtered;
}
