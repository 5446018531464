import React from 'react';
import {
  PageWithHeader,
  Content,
  InfoCard,
  Link,
} from '@backstage/core-components';
import { Grid, ListItem, ListItemText, List, ListItemIcon } from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
import CreateIcon from '@material-ui/icons/Create';
import BookIcon from '@material-ui/icons/Book';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { getGreeting } from './greetings';

const GettingStartedCard = () => (
  <InfoCard title="Getting Started" variant="gridItem">
    <List component="nav" aria-label="getting-started">
      <Link to="catalog">
        <ListItem button>
          <ListItemIcon>
            <ExploreIcon/>
          </ListItemIcon>
          <ListItemText primary="Explore Babel" />
        </ListItem>
      </Link>
      <Link to="docs/default/resource/babel-user-guide/getting-started">
        <ListItem button>
          <ListItemIcon>
            <CreateIcon/>
          </ListItemIcon>
          <ListItemText primary="Add a service"/>
        </ListItem>
      </Link>
      <Link to="docs/default/resource/babel-user-guide/building-relations">
        <ListItem button>
          <ListItemIcon>
            <BubbleChartIcon/>
          </ListItemIcon>
          <ListItemText primary="Build Relationships"/>
        </ListItem>
      </Link>
      <Link to="docs/default/resource/babel-user-guide/well-known">
        <ListItem button>
          <ListItemIcon>
            <ListAltIcon/>
          </ListItemIcon>
          <ListItemText primary="Well-known Directory"/>
        </ListItem>
      </Link>
    </List>
  </InfoCard>
);

const ResourcesCard = () => (
  <InfoCard title="Resources" variant="gridItem">
    <List component="nav" aria-label="getting-started">
      <Link to="https://api-guidelines.eflangtech.com/" target="_blank" rel="noopener">
        <ListItem button>
          <ListItemIcon>
            <BookIcon/>
          </ListItemIcon>
          <ListItemText primary="API Guidelines"/>
        </ListItem>
      </Link>
      <Link to="https://docs.terraform.eflangtech.com/" target="_blank" rel="noopener">
        <ListItem button>
          <ListItemIcon>
            <BookIcon/>
          </ListItemIcon>
          <ListItemText primary="Terraform Modules"/>
        </ListItem>
      </Link>
    </List>
  </InfoCard>
);

const NewEmployeeCard = () => (
  <InfoCard title="New at EF?" variant="gridItem">
    <List component="nav" aria-label="getting-started">
      <Link to="https://ef-ilc.atlassian.net/wiki/spaces/ON/pages/772145682/Engineering+Onboarding+Home" target="_blank" rel="noopener">
        <ListItem button>
          <ListItemIcon>
            <BookIcon/>
          </ListItemIcon>
          <ListItemText primary="General Onboarding Guide"/>
        </ListItem>
      </Link>
      <Link
        to="https://teams.microsoft.com/l/team/19%3aBHgOA9nEkKN6J8mrAxor_folj5zioTu8WGBoqtR1cbE1%40thread.tacv2/conversations?groupId=766ca3f8-a28c-401c-b055-747844c1f138&tenantId=f0d1c6fd-dff0-486a-8e91-cfefefc7d98d"
        target="_blank"
        rel="noopener"
      >
        <ListItem button>
          <ListItemIcon>
            <BookIcon/>
          </ListItemIcon>
          <ListItemText primary="LangTech MS Team"/>
        </ListItem>
      </Link>
    </List>
  </InfoCard>
);

export const HomePage = () => {
  const { displayName } = useUserProfile();
  const greeting = getGreeting();

  return (
    <PageWithHeader
      themeId="none"
      style={{ backgroundImage: 'unset', backgroundColor: '#003C64' }}
      title={
        <>
          {greeting.text}, {displayName} 👋
        </>
      }
      pageTitleOverride="Babel Dashboard"
      subtitle={
      <>
        That means {greeting.variant} in {greeting.language}. {greeting.romanized ? `Its romanized as "${greeting.romanized}".` : ''}
      </>
      }
    >
      <Content>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6} lg={4}>
            <GettingStartedCard />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <ResourcesCard />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <NewEmployeeCard />
          </Grid>
        </Grid>
      </Content>
    </PageWithHeader>
  );
};
